<template>
  <Modal
    :title="
      isEditing
        ? 'Detail Attendance Adjustment'
        : 'Request Attendance Adjustment'
    "
    :show="show"
    :class="'modal-allowed-capacity'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Date"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    placeholder="Date"
                    icon-right="calendar"
                    id="date-picker-calendar"
                    :disabled="isEditing"
                    :max-date="maxDate"
                    v-model="formData.selectedDate"
                    @input="getDataAttendanceByDate(formData.selectedDate)"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="clock in/ clock out"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Clock in/ Clock Out"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-select
                    v-model="formData.selectedAttendanceOption"
                    expanded
                    placeholder="Clock In/ Clock Out"
                    class="hover"
                    :disabled="isEditing"
                  >
                    <option
                      v-for="option in attendanceOptions"
                      :value="option.id"
                      :key="'attendance-' + option.id"
                    >
                      {{
                        'Clock In (' +
                        formatTimeSelect(option.startTime) +
                        ') - ' +
                        'Clock Out (' +
                        formatTimeSelect(option.endTime) +
                        ')'
                      }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 request-section-radio-tooltip">
              <div v-if="!isEditing">
                <ValidationProvider name="additional approver">
                  <PICTooltip />
                  <PIC
                    :isEditing="isEditing"
                    :listUser="listUser"
                    :isFetchingUser="isFetchingUser"
                    @getUser="getUserList"
                    @selectUser="selectUser"
                    @getMoreUser="getMoreUserList"
                  />
                </ValidationProvider>
              </div>
              <div v-else>
                <b-field label="PIC">
                  <p>
                    <b-icon icon="account" class="request-approver-icon mr-2" />
                    {{ selectedUser }}
                  </p>
                </b-field>
              </div>
            </div>
            <div class="column is-6">
              <ValidationProvider
                name="start time"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Start Time"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-timepicker
                    hour-format="24"
                    placeholder="e.g 08:00"
                    icon-right="clock"
                    v-model="formData.formattedStartTime"
                    :disabled="isEditing && notUpdatable"
                  ></b-timepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-6">
              <ValidationProvider
                name="end time"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="End Time"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-timepicker
                    hour-format="24"
                    placeholder="e.g 12:00"
                    icon-right="clock"
                    v-model="formData.formattedEndTime"
                    :disabled="isEditing && notUpdatable"
                  ></b-timepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <b-button
                v-if="selectedColumn"
                expanded
                type="is-primary"
                :loading="loadingSubmit"
                :disabled="isEditing && notUpdatable"
                @click="onSubmit"
              >
                {{ isEditing ? 'Update' : 'Request Attendance Adjustment' }}
              </b-button>

              <b-button
                v-else
                expanded
                type="is-primary"
                :loading="loadingSubmit"
                @click="onSubmit"
              >
                {{ 'Request Attendance Adjustment' }}
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'
import PICTooltip from '../../../components/PIC/PICTooltip.vue'
import PIC from '../../../components/PIC/PIC.vue'
import moment from 'moment'

export default {
  components: { Modal, PICTooltip, PIC },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    notUpdatable: {
      type: Boolean,
      default: false,
    },
    listUser: {
      type: Array,
      default: null,
    },
    isFetchingUser: {
      type: Boolean,
      default: false,
    },
    selectedColumn: {
      type: Object,
      default: null,
    },
    selectedUser: {
      type: String,
      default: null,
    },
    attendanceOptions: {
      type: Array,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit fetch user list.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     * @param event listener
     */
    selectUser(event) {
      this.$emit('selectUser', event)
    },

    /**
     * Emit get more user list.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },

    /**
     * Emit get data activity attendance by date
     * @param event listener
     */
    getDataAttendanceByDate(event) {
      this.$emit('getDataAttendanceByDate', event)
    },

    /**
     * Set Time format
     * @param {Date} str - date time string
     * @return {Date} formatted time string
     */
    formatTimeSelect(str) {
      return moment(moment.utc(str).local().toISOString()).format('HH:mm')
    },
  },
}
</script>
