var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-allowed-capacity',attrs:{"title":_vm.isEditing
      ? 'Detail Attendance Adjustment'
      : 'Request Attendance Adjustment',"show":_vm.show,"isCancel":false,"isSubmit":false},on:{"hide":_vm.hide,"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14px-normal form-timeoff-type",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Date","icon-right":"calendar","id":"date-picker-calendar","disabled":_vm.isEditing,"max-date":_vm.maxDate},on:{"input":function($event){return _vm.getDataAttendanceByDate(_vm.formData.selectedDate)}},model:{value:(_vm.formData.selectedDate),callback:function ($$v) {_vm.$set(_vm.formData, "selectedDate", $$v)},expression:"formData.selectedDate"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"clock in/ clock out","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Clock in/ Clock Out","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{staticClass:"hover",attrs:{"expanded":"","placeholder":"Clock In/ Clock Out","disabled":_vm.isEditing},model:{value:(_vm.formData.selectedAttendanceOption),callback:function ($$v) {_vm.$set(_vm.formData, "selectedAttendanceOption", $$v)},expression:"formData.selectedAttendanceOption"}},_vm._l((_vm.attendanceOptions),function(option){return _c('option',{key:'attendance-' + option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s('Clock In (' + _vm.formatTimeSelect(option.startTime) + ') - ' + 'Clock Out (' + _vm.formatTimeSelect(option.endTime) + ')')+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12 request-section-radio-tooltip"},[(!_vm.isEditing)?_c('div',[_c('ValidationProvider',{attrs:{"name":"additional approver"}},[_c('PICTooltip'),_c('PIC',{attrs:{"isEditing":_vm.isEditing,"listUser":_vm.listUser,"isFetchingUser":_vm.isFetchingUser},on:{"getUser":_vm.getUserList,"selectUser":_vm.selectUser,"getMoreUser":_vm.getMoreUserList}})],1)],1):_c('div',[_c('b-field',{attrs:{"label":"PIC"}},[_c('p',[_c('b-icon',{staticClass:"request-approver-icon mr-2",attrs:{"icon":"account"}}),_vm._v(" "+_vm._s(_vm.selectedUser)+" ")],1)])],1)]),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"start time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Start Time","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-timepicker',{attrs:{"hour-format":"24","placeholder":"e.g 08:00","icon-right":"clock","disabled":_vm.isEditing && _vm.notUpdatable},model:{value:(_vm.formData.formattedStartTime),callback:function ($$v) {_vm.$set(_vm.formData, "formattedStartTime", $$v)},expression:"formData.formattedStartTime"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"end time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"End Time","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-timepicker',{attrs:{"hour-format":"24","placeholder":"e.g 12:00","icon-right":"clock","disabled":_vm.isEditing && _vm.notUpdatable},model:{value:(_vm.formData.formattedEndTime),callback:function ($$v) {_vm.$set(_vm.formData, "formattedEndTime", $$v)},expression:"formData.formattedEndTime"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[(_vm.selectedColumn)?_c('b-button',{attrs:{"expanded":"","type":"is-primary","loading":_vm.loadingSubmit,"disabled":_vm.isEditing && _vm.notUpdatable},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Update' : 'Request Attendance Adjustment')+" ")]):_c('b-button',{attrs:{"expanded":"","type":"is-primary","loading":_vm.loadingSubmit},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s('Request Attendance Adjustment')+" ")])],1)])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }